import type { NextPage } from 'next';
import Oops from './oops';

const Custom404: NextPage = () => (
  <Oops
    pageName="Something's missing"
    title="Something's missing"
    text="We can't seem to find the page you are looking for"
    href="/"
    button="BACK TO DASHBOARD"
  />
);
export default Custom404;
